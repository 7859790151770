import React, { useEffect } from "react"
import AnimateTexts from "../../components/animations/animate-texts"
import "./parallax-gallery.css"
import AnimateFade from "../../components/animations/animate-fade"
import gsap from "gsap"
import { imageKitLink } from "../../utils/fomatters"

const ParallaxGallery = ({transitionStatus}) => {

    const images = [
        {
            url: imageKitLink('/all-images/hair-styling-clay-2_800.jpg', { tr: 'f-webp' }),
            speed: 5
        },
        {
            url: imageKitLink('/web_assets/men-black-3.jpg', { tr: 'f-webp' }),
            speed: 2
        },
        {
            url: imageKitLink('/product_images_v3/serum_extra.jpg', { tr: 'f-webp,h-500' }),
            speed: 5
        },
        {
            url: imageKitLink('/web_assets/man-in-black.jpg', { tr: 'f-webp' }),
            speed: 7
        },
        {
            url: imageKitLink('/web_assets/man-black-2.jpg', { tr: 'f-webp' }),
            speed: 4
        },
        {
            url: imageKitLink('/all-images/shaving-brush-2_800.jpg', { tr: 'f-webp' }),
            speed: 4
        },
        {
            url: imageKitLink('/all-images/face-moisturizer-2_800.jpg', { tr: 'f-webp' }),
            speed: 3
        },
        {
            url: imageKitLink('/product_images_v3/serum_model.jpg', { tr: 'f-webp,h-600' }),
            speed: 2
        }
    ];

    // Image Scroll
    useEffect(() => {

        let section = document.querySelector(`.about_header`);
        let img: NodeListOf<HTMLDivElement> = section.querySelectorAll(`.about__gallery__media__box`);

        img.forEach((i) => {
            let speed:number = parseInt(i.dataset.animateSpeed);
            gsap.to(i, {
                y: -50 * speed,
                ease: "none",
                scrollTrigger: {
                    trigger: section,
                    start: "top top",
                    end: "bottom top",
                    scrub: 0.8,
                }
            });
        })

    }, [])

    return (
        <section className={"about_header overflow-hidden relative"}>
            <AnimateTexts className={`about_header_text`} transitionStatus={transitionStatus} delay={200}>
                <p className={"text-gray-800 font-semibold font-sans uppercase tracking-widest text-md text-center mb-2"} data-text-animate={true}>
                    OUR STORY
                </p>
                <h1 className={"text-gray-800 font-bold font-display text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl text-center px-4"} data-text-animate={true}>
                    Change the world by <br/>changing what you use!
                </h1>
            </AnimateTexts>
            <AnimateFade className={"about_gallery"} transitionStatus={transitionStatus} delay={500}>
                <>
                    {
                        images.map((img, index) => {
                            return (
                                <div key={"img_"+index} className={`about__gallery__media about__gallery__media--${index+1}`} data-animate-fade={true}>
                                    <div className={"about__gallery__media__box"} data-animate-speed={img.speed}>
                                        <img className={"about__gallery__media__image"} alt={""} src={img.url}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            </AnimateFade>
        </section>
    )

}

export default ParallaxGallery;