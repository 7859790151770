import React, { useEffect } from "react"
import SEO from "../../components/seo/seo"
import ParallaxGallery from "../../page-components/about-components/parallax-gallery"
import CustomContainer from "../../components/general/contianer"
import gsap from "gsap"
import AnimateTexts from "../../components/animations/animate-texts"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import AnimateFade from "../../components/animations/animate-fade"
import Footer from "../../components/footer/footer"

gsap.registerPlugin(ScrollTrigger);

const AboutPage = ({ transitionStatus }) => {

    // Image Scroll
    useEffect(() => {

        let section = document.querySelector(`.about-2`);
        let img: HTMLDivElement = section.querySelector(`.about-2-img`);
        let imgContainer: HTMLDivElement = section.querySelector(`.about-2-img-container`);

        gsap.to(img, {
            y: -150,
            ease: "none",
            scrollTrigger: {
                trigger: section,
                start: "top bottom",
                end: "bottom top",
                scrub: 0.8,
            }
        });
        gsap.to(imgContainer, {
            y: 100,
            ease: "none",
            scrollTrigger: {
                trigger: section,
                start: "top bottom",
                end: "bottom top",
                scrub: 0.8,
            }
        });

    }, [])

    return (
        <>
            <SEO
                description={"Check out groomd personal grooming for males multi-functional skincare products made with 100% natural ingredients to get confident, attractive look and a best version of yourself."}
                title={"Men's Personal Hygiene Products"}
                pathname={"/about"}
                keywords={['personal grooming for males',"men's personal hygiene"]}
            />
            <ParallaxGallery transitionStatus={transitionStatus}/>
            <section className={"about-2 py-10 sm:py-32 flex justify-center bg-background-dark"}>
                <CustomContainer className={"flex"}>
                    <div className={"mx-auto max-w-7xl grid grid-cols-1 sm:grid-cols-2 gap-8"}>
                        <AnimateFade className={"about-2-image"} rootClass={"hidden sm:block"} duration={0.3} delay={200} transitionStatus={transitionStatus}>
                            <div className={"w-full aspect-w-5 aspect-h-6 overflow-hidden  about-2-img-container"} style={{  }} data-animate-fade={true}>
                                <img alt={"Groomd Products"} src={"https://ik.imagekit.io/groomd/product_images_v3/face_model.jpg?tr=f-webp,w-500"} className={"about-2-img object-cover w-full"}/>
                            </div>
                        </AnimateFade>
                        <AnimateTexts className={"about-2-text"} transitionStatus={transitionStatus}>
                            <p className={"text-gray-900 font-semibold font-sans text-2xl max-w-sm mt-12"} data-text-animate={true}>
                                Grooming is not just about how you look but it's also about how you feel.
                            </p>
                            <br/>
                            <br/>
                            <p className={"text-gray-900 font-medium font-sans text-base max-w-md mb-3"} data-text-animate={true}>
                                Groomd was founded to make you feel more confident everyday - using ingredients that are 100% vegan and cruelty free. We believe grooming for men should be easy, effortless, and natural.
                            </p>
                            <p className={"text-gray-900 font-medium font-sans text-base max-w-md mb-3"} data-text-animate={true}>
                                The collection includes multifunctional skincare products to support the men in delivering the best version of themselves. Groomd provides men with quality products to create the look they want.We aim to upgrade your grooming regime that’ll not only be simple but will help you introduce the most attractive and impeccable self to the world.
                            </p>
                            <p className={"text-gray-900 font-medium font-sans text-base max-w-md"} data-text-animate={true}>
                                Be the man that looks good and feels good!
                            </p>
                        </AnimateTexts>
                    </div>
                </CustomContainer>
            </section>
            <section className={"about-3 py-10 sm:py-32 flex justify-center"}>
                <CustomContainer className={"flex"}>
                    <div className={"mx-auto max-w-7xl"}>
                        <AnimateTexts className={"about-3-text"} transitionStatus={transitionStatus}>
                            <p className={"text-gray-800 font-semibold font-sans uppercase tracking-widest text-md mt-12 mb-2"} data-text-animate={true}>
                                Why Groomd
                            </p>
                            <p className={"text-gray-900 font-semibold font-sans text-2xl max-w-sm"} data-text-animate={true}>
                                We are 100% vegan & cruelty-free and dermatologically tested.
                            </p>
                            <br/>
                            <br/>
                            <p className={"text-gray-900 font-medium font-sans text-base max-w-md mb-3"} data-text-animate={true}>
                                We formulate and develop every product from scratch with a 100% commitment towards quality, precision, and functionality.
                                Everything that we produce is formulated keeping you in mind. We offer a head to toe range of grooming essentials which goes through an extensive amount of research and testing.
                                Our entire collection of products is SLS and Paraben free which makes sure that your skin and hair follicles are safe from these harmful chemicals. We develop our products using natural and healthy ingredients chosen specifically for men’s skin to provide you with soothing, powerful, and safe skincare. Our products will help you protect and relax the skin.
                            </p>
                            <p className={"text-gray-900 font-medium font-sans text-base max-w-md mb-3"} data-text-animate={true}>
                                Just like we care for you, we also care about animals and make sure that no harm is caused to them during the making and testing of the products.
                                Our products are considerate towards the planet and more suitable for your body.
                            </p>
                            <p className={"text-gray-900 font-medium font-sans text-base max-w-md"} data-text-animate={true}>
                                When you think of grooming, think of Groomd.
                            </p>
                        </AnimateTexts>
                    </div>
                </CustomContainer>
            </section>
            <Footer transitionStatus={transitionStatus}/>
        </>
    )
}

export default AboutPage
